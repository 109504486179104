<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 관찰기간 -->
          <c-datepicker
            :range="true"
            label="관찰기간"
            name="period"
            v-model="period"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 관찰부서 -->
          <c-dept
            type="search"
            label="관찰부서"
            name="period"
            v-model="searchParam.deptCd"
          />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
          <!-- 작업공정 -->
          <c-process
            :editable="editable"
            label="작업공정"
            name="process"
            type="process" 
            v-model="searchParam.process">
          </c-process>
        </div>
      </template>
    </c-search-box>
    <div class="card cardcontents">
      <!-- <div class="card-header q-table-header">
        안전관찰
        <div class="card-more">
          <q-btn-group outline >
            <c-btn label="관찰등록" :editable="editable" icon="add" @btnClicked="onItemClick" />
          </q-btn-group>
        </div>
      </div> -->
      <div class="card-body qtable-card-body">
        <q-table
          grid
          class="qtable-card"
          title=""
          :data="grid.data"
          :columns="grid.columns"
          hide-header
          hide-bottom
          :rows-per-page-options="[0]"
          virtual-scroll
        >
          <template v-slot:item="props">
            <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3">
              <q-card class="mobileTableCardLayer" @click="linkClick(null,props.row)">
                <q-card-section class="grid-card-etc">
                  <div :class="['text-grid-etc', getColorStatus(props.row.ctoCompleteFlag)]">
                    {{ props.row.ctoCompleteFlagName }}
                  </div>
                  <div class="txt-box-grid text-gray-box">
                    {{ props.row.observeUserName }}
                  </div>
                </q-card-section>
                <q-card-section>
                  <div class="text-grid-title q-mt-sm q-mb-xs">{{ props.row.sopName }}</div>
                </q-card-section>
                <q-card-section class="grid-card-etc">
                  <div class="text-grid-etc">
                    {{ props.row.ctoDate }} 
                  </div>
                </q-card-section>
              </q-card>
            </div>
          </template>
        </q-table>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-3" v-if="grid.data.length === 0">
          <q-card class="mobileTableCardLayer">
            <q-card-section horizontal class="text-center">
              <q-card-section class="q-pt-xs">
                <div class="text-grid-title q-mt-sm q-mb-xs">데이터가 존재하지 않습니다.</div>
              </q-card-section>
            </q-card-section>
          </q-card>
        </div>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'cto',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'sopName',
            field: 'sopName',
            label: '관찰작업',
            align: 'center',
            sortable: true,
            style: 'width:200px',
            type: 'link',
          },
          {
            name: 'ctoDate',
            field: 'ctoDate',
            label: '관찰일자',
            align: 'center',
            sortable: true,
            style: 'width:100px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        process: '',
        deptCd: '',
        startYmd: '',
        endYmd: '',
      },
      period: ['', ''],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  beforeRouteLeave(to, from, next) { // history.back 일경우 팝업이라면 팝업을 닫자
    if (this.popupOptions.visible) {
      this.closePopup();
      next(false);
    } else {
      next();
    }
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.sop.cto.basic.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.searchParam.startYmd = this.period[0];
      this.searchParam.endYmd = this.period[1];
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    onItemClick() {
      this.linkClick(null);
    },
    /* eslint-disable no-unused-vars */
    linkClick(evt,row) {
      this.popupOptions.title = '안전관찰 상세'; // 안전관찰 상세
      this.popupOptions.param = {
        sopCtoId: row ? row.sopCtoId : '',
      };
      this.popupOptions.target = () => import(`${'./ctoDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    getColorStatus(cd) {
      var cls = '';
      if (!cd) cd = 'N'
      switch(cd) {
        case 'N': // 계획완료
          cls = 'txt-box-grid text-orange-box';
          break;
        case 'Y': // 교육완료
          cls = 'txt-box-grid text-primary-box';
          break;
      }
      return cls;
    },
  }
};
</script>
